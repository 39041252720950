<template>
  <LayoutBox :loading="loading" title="各网点预警一览">
    <div slot="layoutBox-right" style="width: 120px">
      <Select
        v-model="queryParam.maxResults"
        box-shadow
        :allow-clear="false"
        custom
        :replace-fields="{
          lable: 'msg',
          value: 'code',
        }"
        :options="customerPage"
        placeholder="请选择每页条数"
      ></Select>
    </div>
    <ScrollTable
      ref="scrollTable"
      v-model="loading"
      is-global-query
      :query="{
        status: '02',
        ...queryParam,
      }"
      :is-auto="false"
      :columns="columns"
      :url="`/${$businessISG}/monitor/hall/warning-page`"
    ></ScrollTable>
  </LayoutBox>
</template>

<script>
import { mapState } from 'vuex';
import LayoutBox from '@/views/screen/components/LayoutBox.vue';
import ScrollTable from '@/views/screen/components/ScrollTable.vue';
export default {
  //组件注册
  components: {
    LayoutBox,
    Select: () => import('@/views/screen/components/Select.vue'),
    ScrollTable,
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      queryParam: {},
      columns: [
        {
          title: '机构',
          field: 'organizationText',
          width: '30%',
        },
        {
          title: '时间',
          field: 'warningTime',
          width: '40%',
        },
        {
          title: '预警内容',
          field: 'analysisItemText',
          width: '30%',
        },
      ],
    };
  },
  //计算属性
  computed: {
    ...mapState({
      customerPage: (state) =>
        state.screenStore.commondict['MonitorDataCustomerPageEnum']
          ? state.screenStore.commondict['MonitorDataCustomerPageEnum']
          : [],
    }),
  },
  //属性监听
  watch: {
    queryParam: {
      deep: true,
      handler: function () {
        this.$nextTick(() => {
          this.$refs.scrollTable.getData(this.queryParam);
        });
      },
    },
    refreshKey() {
      this.$nextTick(() => {
        this.$refs.scrollTable.refresh();
      });
    },
  },
  //DOM访问
  mounted() {
    if (this.customerPage[0])
      this.queryParam = {
        maxResults: this.customerPage[0].code,
      };
  },
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped></style>
